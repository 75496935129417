import { http } from '../../http/request';
import { paths } from '../paths';
import { RendEmailType } from './types';

// 重发邀请邮件
export function postInviteFriendsResendEmailApi(id: string) {
  return http.post(paths['invite_friends_resend_email'], { id });
}

// 发送邀请邮件
export function postInviteFriendsRendEmailApi(params: RendEmailType) {
  return http.post(paths['invite_friends_send_email'], params);
}

// 活动任务列表
export function getInviteFriendsListApi() {
  return http.get<object>(paths['activity_missions'], { params: { type: '9,36', category: 4 } });
}

// 奖励累计
export function getInviteFriendsRewardTotalApi() {
  return http.get(paths['invite_friends_reward_total']);
}

// 推荐总览
export function getInviteFriendsSummaryApi() {
  return http.get(paths['invite_friends_summary']);
}

// 邀请记录
export function getInviteFriendsSendRecordsApi() {
  return http.get(paths['invite_friends_send_records']);
}

// 奖励记录
export function getInviteFriendsRewardRecordsApi() {
  return http.get(paths['invite_friends_reward_records']);
}

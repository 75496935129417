/**
 * 与代理中心有关的接口合集
 */

import { AffiliateBalanceItem, BarGraphType, InviteLinkListItem, TradeListItem, TradeTab, UserListItem } from '@/core/shared';
import { http } from '../../http/request';
import { paths } from '../paths';
import { AffiliateCreateInviteLinkParamsType, CampaignCreateType, CampaignShortCreateType, CampaignShortUpdateType, CampaignUpdateType, RecordExportParamsType, RecordParamsType, TeamsDetailOverviewParam, TeamsListParam, TeamsRateLogsParam, TeamsSetRateParam, UserCreateAgentType, UserDescendantsType } from './types';

/** 代理中心——提现 */
export function postAffiliateWithdrawApi(currency: string, amount: number) {
  return http.post(paths['affiliate_withdraw'], { amount, currency });
}

/** 代理中心——获取用户列表 */
export function getAffiliateWithdrawListApi(page?: number) {
  return http.get<{
    count: number;
    list: [];
  }>(paths['affiliate_withdraw_history_list'], { params: { page, rows: 10 } });
}

/** 代理中心——获取团队管理列表 */
export function getAffiliateTeamsListApi(type: number, currency: string, dateGe: string, dateLe: string, orderBy: number | string, order: string, uid?: string, username?: string, page?: number, ratio?: number | string, pid?: string) {
  return http.get<{
    count: number;
    list: [];
  }>(paths['affiliate_teams_list'], {
    params: {
      type,
      currency,
      dateGe,
      dateLe,
      orderBy,
      order,
      uid,
      username,
      page,
      rows: 10,
      ratio,
      pid,
    },
  });
}

/** 代理中心——获取返佣列表 */
export function getAffiliateStepsListApi() {
  return http.get<{
    spot_steps: { disabled: boolean; value: number }[];
    swap_steps: { disabled: boolean; value: number }[];
  }>(paths['affiliate_steps_list']);
}
/** 代理中心——用户现货升点 */
export function upgradeSpotRateApi(descendant: string, rate: number) {
  return http.post(paths['affiliate_spot_upgrade'], {
    descendant,
    rate,
    type: 1,
  });
}

/** 代理中心——用户永续升点 */
export function upgradeSwapRateApi(descendant: string, rate: number) {
  return http.post(paths['affiliate_swap_upgrade'], {
    descendant,
    rate,
    type: 3,
  });
}

/** 代理中心——获取返佣记录列表 */
export function getRecordListApi(params: RecordParamsType) {
  return http.get(paths['affiliate_record_list'], { params });
}

/** 代理中心——获取返佣记录列表 */
export function getRecordExportApi(params: RecordExportParamsType) {
  return http.get<object>(paths['affiliate_record_export'], {
    params,
    responseType: 'blob',
  });
}

/** 代理中心——获取总览信息 */
export function getAffiliateSummaryApi(params: { currency?: string }) {
  return http.get<{
    tcommission: number;
    ycommission: number;
    hcommission: number;
    invites: number;
  }>(paths['affiliate_summary'], { params });
}

/** 代理中心——获取收入信息 */
export function getAffiliateSummaryCommissionApi(params: { currency?: string }) {
  return http.get<{
    commissions: { currency: string; hcommissionValue: number }[];
  }>(paths['affiliate_summary_commission'], { params });
}

/** 代理中心——获取用户信息 */
export function getAffiliateUserinfoApi() {
  return http.get<{
    username: string;
    uid: string;
    refer: string;
    avatar: string;
    swapRatio: number;
    spotRatio: number;
  }>(paths['affiliate_userinfo']);
}

/** 代理中心——获取直属数据/团队数据 */
export function getAffiliateBarGraphDataApi(type: BarGraphType, currency: string, dateGe: string, dateLe: string) {
  return http.get<{
    data1: { date: string; value: number }[];
    data2: { date: string; value: number }[];
    sum1: number;
    sum2: number;
  }>(paths['affiliate_bar_graph'], {
    params: { type, currency, dateGe, dateLe },
  });
}

/** 代理中心——获取交易直属数据/团队数据 */
export function getAffiliateTradeDataApi(type: TradeTab, currency: string, dateGe: string, dateLe: string, uid?: string) {
  return http.get<{
    data1: TradeListItem[];
    data2: TradeListItem[];
  }>(paths['affiliate_trading_data'], {
    params: { type, currency, dateGe, dateLe, uid },
  });
}

/** 代理中心——获取邀请链接列表 */
export function getInviteLinkListApi() {
  return http.get<InviteLinkListItem[]>(paths['affiliate_invite_link_list']);
}

/** 代理中心——获取邀请链接列表 */
export function getAffiliateInviteDomainsApi() {
  return http.get<string[]>(paths['affiliate_share_domains']);
}

/** 代理中心——新增邀请链接 */
export function addAffiliateInviteLinkApi(source: string, link: string) {
  return http.post(paths['affiliate_add_invite_link'], { source, link });
}

/** 代理中心——按 id 删除邀请链接 */
export function deleteInviteLinkByIdApi(id: string) {
  return http.post(`${paths['affiliate_delete_invite_link']}/${id}`);
}

/** 代理中心——获取用户列表 */
export function getAffiliateUserListApi(source: string, currency: string, dateGe: string, dateLe: string, uid?: string, username?: string, page?: number) {
  return http.get<{
    count: number;
    list: UserListItem[];
  }>(paths['affiliate_user_list'], {
    params: { source, currency, dateGe, dateLe, uid, username, page, rows: 10 },
  });
}

/**
 * 获取公告列表
 */
export function getAffiliateNoticesApi() {
  return http.get(paths['affiliate_notices']);
}

/**
 * 获取新增用户
 */
export function getAffiliateNewUserDailyApi(params: { dateGe: number; dateLe: number; currency?: string }) {
  return http.get(paths['affiliate_new_user_daily'], { params });
}

/**
 * 获取客服TG号
 */
export function getAffiliateTelegramApi() {
  return http.get(paths['affiliate_telegram']);
}

/**
 * 获取客服邮箱
 */
export function getAffiliateEmailApi() {
  return http.get(paths['affiliate_email']);
}

/**
 * 获取用户信息
 */
export function getAffiliateAccountApi() {
  return http.get(paths['affiliate_account']);
}

/**
 * 注册充值用户
 */
export function getAffiliateInviteDataApi(params: { page: number; rows: number }) {
  return http.get(paths['affiliate_invite_data'], { params });
}

/**
 * 总提币
 */
export function getAffiliateWithdrawDataApi(params: { dateGe: number; dateLe: number; currency?: string }) {
  return http.get(paths['affiliate_withdraw_data'], { params });
}

/**
 * 总冲币
 */
export function getAffiliateDepositDataApi(params: { dateGe: number; dateLe: number; currency?: string }) {
  return http.get(paths['affiliate_deposit_data'], { params });
}

/**
 * 我的佣金
 */
export function getAffiliateCommissionDataApi(params: { dateGe: number; dateLe: number; currency?: string }) {
  return http.get(paths['affiliate_commission_data'], { params });
}

/**
 * 交易量手续费
 */
export function getAffiliateTradeFeeDataApi(params: { dateGe: number; dateLe: number; currency?: string }) {
  return http.get(paths['affiliate_trade_fee_data'], { params });
}

/**
 *  链接统计-长链接列表
 */
export function getAffiliateCampaignOverviewApi(params: { startTime: number; endTime: number; sourceUrl?: string }) {
  return http.get(paths['affiliate_campaign_overview'], { params });
}

/**
 *  链接统计-短链接列表
 */
export function getAffiliateCampaignShortOverviewApi(params: { startTime: number; endTime: number; sourceUrl?: string }) {
  return http.get(paths['affiliate_campaign_short_overview'], { params });
}

/**
 *  链接设置-长链接列表
 */
export function getAffiliateCampaignListApi() {
  return http.get(paths['affiliate_campaign_list']);
}

/**
 *  链接设置-短链接列表
 */
export function getAffiliateCampaignShortListApi() {
  return http.get(paths['affiliate_campaign_short_list']);
}

/**
 *  链接设置-域名下拉列表
 */
export function getAffiliateCampaignDomainsApi() {
  return http.get(paths['affiliate_campaign_domains']);
}

/** 代理中心——新增邀请链接 */
export function postAffiliateCampaignCreateApi(params: CampaignCreateType) {
  return http.post(paths['affiliate_campaign_create'], params);
}

/** 代理中心——删除邀请链接 */
export function postAffiliateCampaignRemoveApi(id: string) {
  return http.post(`${paths['affiliate_campaign_remove']}/${id}`);
}

/** 代理中心——编辑邀请链接 */
export function postAffiliateCampaignUpdateApi(params: CampaignUpdateType) {
  return http.post(paths['affiliate_campaign_update'], params);
}

/** 代理中心——新增短邀请链接 */
export function postAffiliateCampaignShortCreateApi(params: CampaignShortCreateType) {
  return http.post(paths['affiliate_campaign_short_create'], params);
}

/** 代理中心——删除短邀请链接 */
export function postAffiliateCampaignShortRemoveApi(id: string) {
  return http.post(`${paths['affiliate_campaign_short_remove']}/${id}`);
}

/** 代理中心——编辑短邀请链接 */
export function postAffiliateCampaignShortUpdateApi(params: CampaignShortUpdateType) {
  return http.post(paths['affiliate_campaign_short_update'], params);
}

/**
 *  短连接-跳转页面下拉选项
 */
export function getAffiliateCampaignPagesApi() {
  return http.get(paths['affiliate_campaign_pages']);
}

/**
 *  直客数据->用户数据
 */
export function getAffiliateUserDescendantsApi(params: UserDescendantsType) {
  return http.get(paths['affiliate_user_descendants'], { params });
}

/**
 *  升级代理
 */
export function postAffiliateCreateAgentApi(params: UserCreateAgentType) {
  return http.post(paths['affiliate_user_create_agent'], params);
}

/**
 *  团队管理-团队列表
 */
export function getAffiliateTeamTeamsApi(params: TeamsListParam) {
  return http.get(paths['affiliate_team_teams'], { params });
}

/**
 *  团队管理-团队详情上半部分
 */
export function getAffiliateTeamDetailApi(id: string) {
  return http.get(paths['affiliate_team_detail'] + `/${id}`);
}

/**
 *  团队管理-团队详情-统计数据
 */
export function getAffiliateTeamDetailOverviewApi(params: TeamsDetailOverviewParam) {
  return http.get(paths['affiliate_team_detail_overview'], { params });
}

/**
 *  团队管理-下级团队列表
 */
export function getAffiliateTeamSubTeamsApi(params: TeamsListParam) {
  return http.get(paths['affiliate_team_sub_teams'], { params });
}

/**
 *  团队管理-升点
 */
export function postAffiliateTeamSetRateApi(params: TeamsSetRateParam) {
  return http.post(paths['affiliate_team_set_rate'], params);
}

/**
 *  团队管理-返佣比例修改历史
 */
export function getAffiliateTeamRateLogsApi(params: TeamsRateLogsParam) {
  return http.get(paths['affiliate_team_rate_logs'], { params });
}

/** 代理中心——获取用户的钱包余额 */
export function getAffiliateBalanceApi() {
  return http.get<AffiliateBalanceItem[]>(paths['affiliate_balance']);
}

/** 代理中心——获取用户的赠金 */
export function getAffiliatePaymentBonusApi() {
  return http.get(paths['affiliate_payment_bonus']);
}

// 获取折扣比例
export function postAffiliateRateApi(data: { ru: string; f: string }) {
  return http.post(paths['affiliate_rate'], data);
}

// 双向返佣-创建
export function postAffiliateCreateInviteLinkApi(data: AffiliateCreateInviteLinkParamsType) {
  return http.post(paths['affiliate_create_invite_link'], data);
}

// 双向返佣-编辑
export function postAffiliateEditInviteLinkApi(data: AffiliateCreateInviteLinkParamsType) {
  return http.post(paths['affiliate_edit_invite_link'], data);
}

// 双向返佣-列表
export function getAffiliateInviteLinksApi(refer?: string) {
  return http.get(paths['affiliate_invite_links'], { params: { refer } });
}

// 双向返佣-统计数据
export function getAffiliateOverviewInviteLinksApi(params: { sourceUrl?: string; startTime: number; endTime: number }) {
  return http.get(paths['affiliate_overview_invite_links'], { params });
}

// 双向返佣-直客数据
export function getAffiliateInviteRegularsApi(params: { refer?: string; page: number; rows: number }) {
  return http.get(paths['affiliate_invite_regulars'], { params });
}

/**
 * 抽奖活动接口合集
 */

import { http } from '../../http/request';
import { paths } from '../paths';

/** 抽奖活动——获取限时任务列表 */
export function getTaskListApi(module: number) {
  return http.get(paths['activity_missions'], { params: { module } });
}

/** 抽奖活动——领取奖励 */
export function getRewardApi(id: string) {
  return http.post(`${paths['variety_activity_collect']}/${id}`);
}

/** 抽奖活动——奖品配置列表 */
export function getRewardListApi(lotteryId: string) {
  return http.get(paths['variety_lottery_prizes'], { params: { lotteryId } });
}

/** 抽奖活动——抽奖次数查询 */
export function getDrawCountApi() {
  return http.get(paths['lottery_get_draw_count']);
}

/** 抽奖活动——开奖 */
export function getRewordApi(lotteryId: string) {
  return http.post(paths['variety_lottery_draw'], { lotteryId, blind: false });
}
